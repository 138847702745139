// DiscordButton.js
import React from 'react';
import { FaDiscord } from 'react-icons/fa';

const DiscordButton = () => {
    const discordInviteLink = 'https://discord.gg/9MzkBfJENu'; // Substitua pelo seu link de convite do Discord

    const openDiscord = () => {
        window.open(discordInviteLink, '_blank');
    };

    return (
        <div className="text-center">
            <button
                onClick={openDiscord}
                style={{
                    backgroundColor: '#2D2E32',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '7px 15px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                    cursor: 'pointer',
                    marginTop: '12',
                    marginLeft: '5',
                }}
                className="btn btn-primary"
            >
                <FaDiscord size={16} style={{ marginRight: '8px' }} />
                Discord
            </button>
        </div>
    );
};

export default DiscordButton;
