import React from "react";

export  function Team()  {
    return (
        <section id="software" className="dark-bg">
          <div class="text-center">
          <h2>Software</h2>
          </div>
            <div className="flex">
                <div className="flex-full">
                    <DownloadCard
                        title="MuInstaServer"
                        date="2024/08/01"
                        icon="../../img/icons/software.svg"
                        description={`MuInstaServer is software that does all the heavy lifting for you. <br /> With just two clicks, you can have your MuOnline server up and running. <br />It's that easy!`}
                        ytvideo="https://www.youtube.com/watch?v=be4hzeXagv4"
                        url="https://angeldevs-team.com/files/AngelDevsDownloader.exe"
                    />


                    
                </div>

            </div>

        </section>
    )
}

function DownloadCard({ title, date, icon, description, ytvideo, url }) {
    return (
        <div className="light-bg software-card">
            <div className="flex justify-space">
                <h3 className="green">{title}</h3>
                <img src={icon} alt={title} style={{ width: 28, height: 28 }} />
            </div>
            <p className="gray">{date}</p>
            <a className="green" href={ytvideo} target="_blank" rel="noopener noreferrer">
                Watch Video
            </a>
            <br /><br />
            <p className="white" dangerouslySetInnerHTML={{ __html: description }} />
            <a className="green" href={url} target="_blank" rel="noopener noreferrer">
                Download
            </a>
        </div>
    )
}
