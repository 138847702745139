import React, { useState } from "react";
import ReactPlayer from "react-player";
import DiscordButton from './DiscordButton';
import WarningPopup from './WarningPopup';

export const Header = (props) => {

  
  return (
    
    <header id="header">
      
      <div className="intro">
      <ReactPlayer
          url="https://angeldevs-team.com/videos/movie.mp4"
          playing
          loop
          muted
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0, opacity: 0.4 }}
        />
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
              {/* <h2>Build your own <span class="highlight">MuOnline Server!</span></h2> */}
              <h2>Simplify Your <span class="highlight">MuOnline</span> Experience</h2>
                <p>Where Passion Meets Code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
