import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBsBFKRSuVyReIzZ8PHWTVrpelBLxR6Yk8",
  authDomain: "muinstaserver.firebaseapp.com",
  databaseURL: "https://muinstaserver-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "muinstaserver",
  storageBucket: "muinstaserver.appspot.com",
  messagingSenderId: "426183093542",
  appId: "1:426183093542:web:62e0c126782937c44725e7",
  measurementId: "G-33VEBWTR2L"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const Purchase = () => {

  var m = new Date();
  var dateTime = m.getUTCDate() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCFullYear() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();
  
  const [hwid, setHwid] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Hook para navegação

  
  const getIPAndLocation = async () => {
    try {
      const response = await fetch('https://ipinfo.io?token=0fbb27fc3fe0dd'); 
      const data = await response.json();
      return { ip: data.ip, country: data.country };
    } catch (error) {
      console.error('Erro ao obter IP e localização:', error);
      return { ip: 'Não disponível', country: 'Não disponível' };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');

    try {
      // Obtém o IP e a localização do usuário
      const { ip, country } = await getIPAndLocation();

      const hwidRef = ref(database, `HWIDManager/${hwid}`);
      console.log('Reference created:', hwidRef);
      
      // Operação de escrita no Firebase com os novos campos
      await set(hwidRef, {
        hwid: hwid,
        purchased: 'true',
        name: name,
        email: email,
        date: dateTime,
        ip: ip,
        country: country,
      });

      console.log('Data written to database');
      setMessage(`HWID ${hwid} activated!`);
      setName("");
      setEmail("");
      setHwid("");
      setTimeout(() => {
        navigate("/#"); // Redireciona para a URL desejada
      }, 2000);
      
    } catch (error) {
      console.error('Failed to submit HWID:', error);
      setMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <div style={{
      backgroundColor: "#2c2c2c",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      padding: "20px"
    }}>
      <div style={{
        padding: "20px",
        backgroundColor: "#2D2E32",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        width: "100%",
        maxWidth: "500px"
      }}>
        <h1>HWID Setup</h1>
        <p>Enter your HWID to activate the software</p>

        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ fontSize: "18px", marginBottom: "5px", color: "#fff" }}>Name:</p>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{
                padding: "8px",
                width: "100%",
                maxWidth: "300px",
                boxSizing: "border-box",
                color: "#2c2c2c"
              }}
            />
          </div>

          <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ fontSize: "18px", marginBottom: "5px", color: "#fff" }}>Email:</p>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{
                padding: "8px",
                width: "100%",
                maxWidth: "300px",
                boxSizing: "border-box",
                color: "#2c2c2c"
              }}
            />
          </div>

          <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{ fontSize: "18px", marginBottom: "5px", color: "#fff" }}>HWID:</p>
            <input
              type="text"
              id="hwid"
              name="hwid"
              value={hwid}
              onChange={(e) => setHwid(e.target.value)}
              required
              style={{
                padding: "8px",
                width: "100%",
                maxWidth: "300px",
                boxSizing: "border-box",
                color: "#2c2c2c"
              }}
            />
          </div>
          
          <button type="submit" style={{
            padding: "10px 20px",
            backgroundColor: "#888",
            color: "#fff",
            border: "none",
            cursor: "pointer",
            marginTop: "10px"
          }}>
            Activate HWID
          </button>
        </form>

        {message && <p style={{ marginTop: "20px", color: "#4dba87" }}>{message}</p>}
      </div>
    </div>
  );
};

export default Purchase;
