import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Software";
// import { Contact } from "./components/contact";
import Purchase from "./components/Purchase"; // Importar a nova página
import Downloads from "./components/Downloads"; // Importar a nova página


import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);



  return (
    <Router>
      <Routes>
        {/* Rota principal que renderiza a página de aterrissagem */}
        <Route 
          path="/" 
          element={
            <div>
              <Navigation />
              <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              {/* <Services data={landingPageData.Services} /> */}
              {/* <Gallery data={landingPageData.Gallery} /> */}
              {/* <Testimonials data={landingPageData.Testimonials} /> */}

              {/* software */}
              <Team data={landingPageData.Team} /> 
               {/* SOFTWARE */}

              {/* <Contact data={landingPageData.Contact} /> */}
            </div>
          } 
        />
        {/* Nova rota para a página de compra */}
        <Route path="/purchase" element={
          <div>
              <Navigation />
              <Purchase />
          </div>
          }
        />
        <Route path="/downloads" element={
          <div>
              <Navigation />
              <Downloads />
          </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
