import React from "react";
import WhatsAppButton from './WhatsAppButton';
import DiscordButton from './DiscordButton';


export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid navbar-custom-margin">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/#page-top">
          AngelDevs - Team
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="/#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li>
              <a href="/#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="/#software" className="page-scroll">
                Software
              </a>
            </li>
            <li>
              <a href="/downloads" className="page-scroll">
                Downloads
              </a>
            </li>
            <li>
              <a href="/purchase" className="page-scroll">
                HWID Setup
              </a>
            </li>
            <li>
              <WhatsAppButton />
            </li>
            <li>
              <DiscordButton />
            </li>


            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            {/* <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
