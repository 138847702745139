import React from "react";

function Downloads()  {
    return (
        <section id="software" className="dark-bg">
          <div class="text-center">
          <h2>Downloads</h2>
          </div>
            <div className="flex">
                <div className="flex-full">
                    <DownloadCard
                        title="MuInstaServer"
                        date="2024/08/01"
                        icon="../../img/icons/software.svg"
                        description={`MuInstaServer is software that does all the heavy lifting for you. <br /> With just two clicks, you can have your MuOnline server up and running. <br />It's that easy!`}
                        ytvideo="https://www.youtube.com/watch?v=be4hzeXagv4"
                        url="https://angeldevs-team.com/files/AngelDevsDownloader.exe"
                    />
                    <DownloadCard
                        title="Redistributable Files Pack"
                        date="2024/08/05"
                        icon="../../img/icons/software.svg"
                        description={` - VC_redist.x86 <br /> - VC_redist.x64 2015-2022 <br /> - NDP452-KB2901907 <br /> - Python-3.11.2-amd64 `}
                        ytvideo=""
                        url="https://angeldevs-team.com/files/Redist_Pack.zip"
                    />

                    <DownloadCard
                        title="S18 Client for MuInstaServer"
                        date="2024/08/06"
                        icon="../../img/icons/software.svg"
                        description={`Season 18 Client for MuInstaServer S18 Installation`}
                        ytvideo=""
                        url="https://angeldevs-team.com/files/S18Client.zip"
                    />




                    
                </div>

            </div>

        </section>
    )
}

function DownloadCard({ title, date, icon, description, ytvideo, url }) {
    return (
        <div className="light-bg software-card">
            <div className="flex justify-space">
                <h3 className="green">{title}</h3>
                <img src={icon} alt={title} style={{ width: 28, height: 28 }} />
            </div>
            <p className="gray">{date}</p>
            {ytvideo && (
                <a className="green" href={ytvideo} target="_blank" rel="noopener noreferrer">
                    Watch Video
                </a>
            )}
            <br /><br />
            <p className="white" dangerouslySetInnerHTML={{ __html: description }} />
            <a className="green" href={url} target="_blank" rel="noopener noreferrer">
                Download
            </a>
        </div>
    );
}


export default Downloads;
