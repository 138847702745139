// WhatsAppButton.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    const phoneNumber = '+351913450846'; // Substitua pelo seu número de telefone com código de país
    const message = 'Olá, gostaria de mais informações!';

    const openWhatsApp = () => {
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <button
            onClick={openWhatsApp}
            style={{
                backgroundColor: '#2D2E32',
                // borderColor: '#25262A',
                border: 'none',
                borderRadius: '5px',
                padding: '7px 15px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                cursor: 'pointer',
                marginTop: '12',
                marginLeft: '25',
            }}
        >
            <FaWhatsapp size={16} style={{ marginRight: '8px' }} />
            Contact
        </button>
    );
};

export default WhatsAppButton;
